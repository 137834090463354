import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import VideoPlayer from '../components/VideoPlayer'
import PaginationLinks from '../components/PaginationLinks';

import {Row, Col, Card, CardText, CardBody, CardTitle} from 'reactstrap'


const videoPage = ({ data, pageContext, location }) => {
    /*
      {
    kind: 'youtube#searchResult',
    etag: '"Bdx4f4ps3xCOOo1WZ91nTLkRZ_c/5USmkTumQ6h_VjHVZGjjXd45VyU"',
    id: { kind: 'youtube#video', videoId: 'LYfZqZpaGrg' },
    snippet: {
      publishedAt: '2019-07-16T11:40:23.000Z',
      channelId: 'UCjH790vqyWOvjeYPNLz3QJw',
      title: 'Candi Girl Skates',
      description: 'Exploring New Paths.',
      thumbnails: [Object],
      channelTitle: 'L OnWheels',
      liveBroadcastContent: 'none'
    }
  },
  */

  /*
    VideoPlayer ({
            videoID:{videos[i].id.videoId},
            t_width:"100%",
            t_height:"250",
            tframeborder:"0"})
  */
  
    //{pageContext.videos[0].id.videoId}
    /*
    var currentPage = parseInt(window.location.href.toString().split('p=')[1])
    var baseLink = window.location.href.toString().split('/?')[0]

    if(!currentPage)
    {
        currentPage = 1;
    }
    */

   var currentPage = 1
   var baseLink = "lonwheels.us/videos"
 
   if(typeof(window) !== 'undefined')
   {
     currentPage = parseInt(window.location.href.toString().split('p=')[1])
     baseLink = window.location.href.toString().split('/?')[0]
   }
   
   if(!currentPage)
   {
       currentPage = 1;
   }

    const videos = pageContext.videos
    const videosPerPage = pageContext.videosPerPage

    
    
    var max = Object.keys(videos).length
    
    var content = []

    var tMax = 0

    let avaliableVideos = []

    for(var  i = 0; i < max; i++)
    {
        if(videos[i].id.kind === 'youtube#video')
        {
            tMax++;
            avaliableVideos.push(i);

            videos[i].snippet.title = videos[i].snippet.title.replace('&amp;','&')
            videos[i].snippet.title = videos[i].snippet.title.replace('&#39;',"'")
        }
    }

    max = tMax;

    const numberOfPages = Math.ceil(max/videosPerPage)

    var videosStart = (currentPage-1)*videosPerPage
    var videosEnd = currentPage*videosPerPage

    

    if(videosEnd > max)
    {
        videosEnd = max
    }
    
    for(i = videosStart; i < videosEnd; i+=2){

        var video1 = 
        <Card>
            <CardTitle className="text-align-center">
                <a href={`https://www.youtube.com/watch?v=${videos[avaliableVideos[i]].id.videoId}`} target="_blank" rel="noopener noreferrer" className="youtube">
                    {videos[avaliableVideos[i]].snippet.title}
                </a>
            </CardTitle>
            {VideoPlayer ({
                    videoID: videos[avaliableVideos[i]].id.videoId,
                    t_width: "100%",
                    t_height: "250",
                    tframeborder: "32px"}
                )}
            <CardBody>
                <CardText>{videos[avaliableVideos[i]].snippet.description}</CardText>
                </CardBody>
        </Card>
        var video2 = ""
        if(i+1 < max)
        {
            video2 = 
            <Card>
                <CardTitle className="text-align-center">
                    <a href={`https://www.youtube.com/watch?v=${videos[avaliableVideos[i+1]].id.videoId}`} target="_blank" rel="noopener noreferrer" className="youtube">
                        {videos[avaliableVideos[i+1]].snippet.title}
                    </a>
                </CardTitle>
                {VideoPlayer ({
                    videoID: videos[avaliableVideos[i+1]].id.videoId,
                    t_width: "100%",
                    t_height: "250",
                    tframeborder: "32px",
            }
                )}
                <CardBody>
                
                <CardText>{videos[avaliableVideos[i+1]].snippet.description}</CardText>
                </CardBody>
            </Card>
        }
        content.push(<Row><Col className="mb-5">{video1}</Col><Col className="mb-5">{video2}</Col></Row>)
    }
    
    


    return(
    <Layout pageTitle={`My Videos (${max})`}>
      <SEO title="My Videos" />
      <div>{content}</div>
      <div>
          <PaginationLinks currentPage={currentPage} numberOfPages={numberOfPages} baseLink={baseLink}/>
      </div>
    </Layout>
  )}



export default videoPage