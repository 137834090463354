import React from 'react'
import {Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginationLinks = ({currentPage = 0 , numberOfPages = 0, baseLink = ''}) => {
    
    if(baseLink === null || baseLink === '')
    {
        return(
            <div>
            <p>unable to load page links at this time</p>
            <p>please contact the website adminstrator</p>
            </div>
        )
    }
    
    const isFirst = currentPage <= 1
    const isLast = currentPage >= numberOfPages 
    const previousPage = 
    currentPage - 1 <= 1 ?
     baseLink : '?p='+ ( currentPage - 1 ).toString()
    const nextPage = '?p=' + (currentPage + 1).toString()

    var gotoFirst = <PaginationItem key="page-first"><PaginationLink first href={baseLink}/></PaginationItem>
    var gotoPrev = <PaginationItem key="page-prev"><PaginationLink previous href={previousPage}/></PaginationItem>
    let links = []
    var gotoNext = <PaginationItem key="page-next"><PaginationLink next href={nextPage}/></PaginationItem>
    var gotoLast = <PaginationItem key="page-last"><PaginationLink last href={`?p=${numberOfPages}`}/></PaginationItem>
    
    if(isFirst)
    {
        gotoFirst = <PaginationItem disabled key="page-first"><PaginationLink first href={baseLink}/></PaginationItem>
        gotoPrev = <PaginationItem disabled key="page-prev"><PaginationLink previous href={previousPage}/></PaginationItem>
    }

    if(isLast)
    {
        gotoNext = <PaginationItem disabled key="page-next"><PaginationLink next href={nextPage}/></PaginationItem>
        gotoLast = <PaginationItem disabled key="page-last"><PaginationLink last href={`?p=${numberOfPages}`}/></PaginationItem>
    }
    

    for(var i = 1; i < numberOfPages+1;i++){
        var link = ''
        if(i === 1){
            if(currentPage === i)
            {
                link = <PaginationItem active key={`page-number${i+1}`}><PaginationLink href={baseLink}>{i}</PaginationLink></PaginationItem>
            }
            else{
                link = <PaginationItem key={`page-number${i+1}`}><PaginationLink href={baseLink}>{i}</PaginationLink></PaginationItem>
            }
        }
        else{
            if(currentPage === i)
            {
                link = <PaginationItem active key={`page-number${i+1}`}><PaginationLink href={`?p=${i}`}>{i}</PaginationLink></PaginationItem>
            }
            else
            {
                link = <PaginationItem key={`page-number${i+1}`}><PaginationLink href={`?p=${i}`}>{i}</PaginationLink></PaginationItem>
            }
        }
        links.push(link)
    }

    

    return (
        <div>
            <Pagination aria-label= "Navigation">
                {gotoFirst}
                {gotoPrev}
                {links}
                {gotoNext}
                {gotoLast}
            </Pagination>
        </div>
    )
}
export default PaginationLinks